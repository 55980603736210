<script setup lang="ts">
import { UIDatepicker, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { useDocumentStore } from '@/store/document'
import { PropType, computed, inject, onMounted, ref, watch } from 'vue'
import {
  DateFieldElementOptions,
  IElement,
  IRecipient,
  Tooltip,
  getSignatureElementColorStyles,
  validateDate,
  DEFAULT_DATE_FORMAT,
  convertToUTC,
  convertUtcToUNIX,
  PageElementOptions,
} from '@gohighlevel/ghl-proposals-common'
import { useFillableFields, usePrintAdjust, useTypography } from '@/composable'

const props = defineProps({
  page: {
    type: Object as PropType<IElement<PageElementOptions>>,
    required: true,
  },
  element: {
    type: Object as PropType<IElement<DateFieldElementOptions>>,
    required: true,
  },
  pageId: {
    type: String,
    required: true,
  },
  tabIndex: {
    type: Number,
    required: true,
  },
  pageRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  parentRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
})

const localParentRef = ref<HTMLElement | undefined>(props.parentRef)
const localPageRef = ref<HTMLElement | undefined>(props.pageRef)
watch(
  () => props.parentRef,
  newVal => {
    localParentRef.value = newVal
  },
  { immediate: true }
)
watch(
  () => props.pageRef,
  newVal => {
    localPageRef.value = newVal
  },
  { immediate: true }
)

const { position: printPosition, elRef } = usePrintAdjust(
  props.page,
  props.element,
  localPageRef,
  localParentRef
)
const isPDFView = inject<boolean>('isPDFView')
const isPreview = inject<boolean>('isPreview')
const { toCapitalize } = useTypography()
const store = useDocumentStore()
const activeRecipient = computed(() => store.activeRecipient)
const isEditing = computed(() => store.isEditing)
const { setActiveField, activeElement } = useFillableFields()
const isActiveTextFieldElemForRecipient = computed(
  () =>
    activeRecipient.value?.id === props.element?.component.options.recipient &&
    activeRecipient.value?.entityName ===
      props.element?.component.options.entityName
)

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id, entityName }) =>
        id === props.element?.component.options.recipient &&
        entityName === props.element?.component.options.entityName
    ) || ({} as IRecipient)
  )
})

const isRecipientHasCompleted = computed(() => {
  return (
    activeRecipient.value &&
    activeRecipient.value?.hasCompleted &&
    isActiveTextFieldElemForRecipient.value
  )
})

const disabled = computed(() => {
  return (
    !store.hasPrevRecipientsCompleted ||
    (!isPreview &&
      !isActiveTextFieldElemForRecipient.value &&
      !isRecipientHasCompleted.value)
  )
})

const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return toCapitalize(firstName ? `${firstName} ${lastName || ''}` : '')
})

const ghostStyles = computed(() => {
  if (props.element?.component.options?.isGhost) {
    const styleMap = props.element?.responsiveStyles.large as any
    const position = styleMap.position
    const scale = styleMap.scale
    const dimensions = styleMap.dimensions
    const stylesArray = []
    if (position) {
      let styleStr = position?.preferBottom
        ? `bottom: ${position.bottom}px;`
        : `top: ${position.top}px; `
      styleStr += position?.preferRight
        ? `right: ${position.right}px;`
        : `left: ${position.left}px;`
      styleStr += 'z-index: 100;'
      stylesArray.push(styleStr)
    }
    if (scale) {
      stylesArray.push(
        `transform: scale(${scale.scaleX}, ${scale.scaleY}) translate3d(0,0,0);`
      )
    }
    if (dimensions) {
      stylesArray.push(
        `height: ${dimensions.height}px; width: ${dimensions.width}px;`
      )
    }
    stylesArray.push('position: absolute;  display: flex;')
    return stylesArray.join(' ').trim()
  }
  return ''
})

onMounted(() => {
  if (store.isAlreadyAccepted) {
    store.updateFillableElement(
      props.element?.id,
      props.pageId,
      props.element?.component.options.text as string,
      true
    )
  }
})
const onUpdateTextField = (text: string) => {
  const timestamp = convertToUTC(text)
  store.updateFillableFieldForRecipient(
    recipient.value.id,
    timestamp as string,
    props.element?.id as string,
    timestamp as string
  )
  store.updateFillableElement(
    props.element?.id,
    props.pageId,
    timestamp as string,
    true
  )
}
const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  return (
    {
      height: dimensionsObj?.height + 'px',
      width: dimensionsObj?.width + 'px',
    } || { height: 'auto', width: 'auto' }
  )
})
const commonStyles = computed(() => {
  return {
    ...dimensions.value,
    border: '0px solid transparent !important',
  }
})
const colorStyles = computed(() => {
  if (isPDFView) {
    return {
      ...commonStyles.value,
      '--n-color-disabled': 'transparent !important',
      backgroundColor: 'transparent !important',
    }
  }
  if (disabled.value) {
    return {
      ...commonStyles.value,
      // border: '0px solid transparent !important',
      'box-shadow': 'none !important',
      '--n-border-hover': '0px solid transparent !important',
      backgroundColor: 'transparent !important',
      '--n-border-disabled': '0px solid transparent !important',
      border: `0px solid transparent !important`,
    }
  }

  return {
    ...styles.value,
    ...commonStyles.value,
    border: `1px solid ${styles.value.borderColor} !important`,
  }
})

const onClick = () => {
  if (
    !isEditing.value &&
    isActiveTextFieldElemForRecipient.value &&
    !activeRecipient.value?.hasCompleted
  ) {
    store.setIsEditing(true)
  }
  setActiveField(props.element?.id as string)
}
</script>

<template>
  <div
    ref="elRef"
    class="date-field-el"
    :class="{ filled: props.element?.component.options.text }"
    :style="ghostStyles"
    :id="props.element?.id"
  >
    <Tooltip direction="bottom" :enable="true">
      <template #trigger>
        <div
          class="print:break-inside-avoid !break-inside-avoid box-border date-field-el-wrapper"
          @click="onClick"
        >
          <span
            v-if="
              element?.component.options.required &&
              !disabled &&
              !isPDFView &&
              !isRecipientHasCompleted
            "
            class="option--required"
            >*</span
          >
          <UIDatepicker
            :tabindex="tabIndex"
            @on-focus="onClick"
            :class="{
              'self-completed':
                props.element?.component.options.text &&
                isRecipientHasCompleted,
              completed:
                props.element?.component.options.text &&
                !isActiveTextFieldElemForRecipient,
              'hidden-suffix':
                isPDFView ||
                (props.element?.component.options.text &&
                  !isActiveTextFieldElemForRecipient) ||
                isRecipientHasCompleted,
              'no-active': isPDFView
                ? true
                : props.element?.component.options.text ||
                  isRecipientHasCompleted,
              'active-el': isPDFView ? true : !isRecipientHasCompleted,
              '!border-2 !border-error-500':
                element?.component.options.textFieldErrorMessage,
            }"
            class="date-field-input rounded-sm !text-sm"
            :style="{
              ...(!props.element?.component.options.text
                ? colorStyles
                : commonStyles),
              'border-width':
                activeElement && activeElement.id === props.element?.id
                  ? '0px'
                  : '1px',
            }"
            :id="`floating_date_field-${props.element?.id}`"
            :placeholder="props.element?.component.options.placeholder"
            :value="
              props.element?.component.options.text
                ? convertUtcToUNIX(props.element?.component.options.text)
                : null
            "
            :format="
              props.element?.component.options.dateFormat || DEFAULT_DATE_FORMAT
            "
            type="date"
            :disabled="disabled"
            :is-date-disabled="(timestamp: number) => (!isRecipientHasCompleted && activeRecipient?.id === props.element?.component.options.recipient) ? validateDate(props.element?.component.options.availableDates, timestamp) : false"
            @update:value="val => onUpdateTextField(val)"
            size="medium"
            :actions="['now', 'clear']"
          />
        </div>
      </template>
      <template #content>
        <UITextSmRegular
          class="capitalize font-semibold px-2 py-1 text-xs"
          :style="{ color: styles.placeholderColor }"
          >{{ recipientName }}</UITextSmRegular
        >
      </template>
    </Tooltip>
  </div>
</template>

<style lang="scss">
.date-field-input {
  .n-input {
    --n-color-disabled: #f1f1f1 !important;
    --n-text-color-disabled: #333 !important;
    --n-color-focus: v-bind('styles.backgroundColor') !important;
    --n-height: v-bind('dimensions.height') !important;
    --n-border-hover: 1px solid v-bind('styles.borderColor') !important;
    --n-border-pressed: 1px solid transparent !important;
    --n-border-focus: 1px solid v-bind('styles.borderColor') !important;
    --n-caret-color: 1px solid transparent !important;
    --n-box-shadow-focus: 1px solid transparent !important;
    --n-loading-color: 1px solid transparent !important;

    --n-border: 1px solid transparent !important;
    --n-color: transparent !important;
    box-shadow: none !important;
    border-radius: 2px;
    .n-input-wrapper {
      padding: 0 !important;
      .n-input__suffix {
        .n-base-icon {
          color: rgb(141, 141, 141) !important;
        }
      }
      .n-input__input-el {
        padding: 0px 2px !important;
        font-weight: 600 !important;
      }
      .n-input__placeholder {
        padding: 0px 2px !important;
        color: v-bind('styles.placeholderColor') !important;
      }
    }
    .n-input__border {
      border: 1px solid transparent;
    }
    &.n-input--disabled {
      cursor: default !important;
      .n-input__placeholder {
        color: #c8c8c8 !important;
      }
      .n-input__suffix {
        .n-base-icon {
          color: #c8c8c8 !important;
        }
      }
    }
  }
  &.hidden-suffix {
    .n-input-wrapper {
      .n-input__suffix {
        visibility: hidden;
      }
    }
  }
  &.completed,
  &.self-completed {
    pointer-events: none !important;
    .n-input.n-input--disabled {
      --n-color-disabled: transparent !important;
    }
  }
}

.option--required {
  position: absolute;
  right: -7px;
  top: -17px;
  color: red;
  font-size: 22px;
  font-weight: 700;
}
.date-field {
  .n-input__input {
    font-size: 14px !important;
  }
}
@media print {
  .date-field-el {
    break-inside: avoid-page !important;
    transform: v-bind('printPosition.top') !important;
  }
}
</style>
