import { DocumentViewerConfigType } from '@gohighlevel/ghl-proposals-common'

const config: DocumentViewerConfigType = {
  development: {
    mode: 'dev',
    PROPOSAL_SERVICE_URL: 'http://localhost:5092/proposals',
    INVOICE_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/invoices',
  },
  staging: {
    mode: 'staging',
    PROPOSAL_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/proposals',
    INVOICE_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/invoices',
  },
  'staging-version': {
    mode: 'staging',
    PROPOSAL_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/proposals',
    INVOICE_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/invoices',
  },
  beta: {
    mode: 'beta',
    PROPOSAL_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/proposals',
    INVOICE_SERVICE_URL:
      'https://staging.services.leadconnectorhq.com/invoices',
  },
  production: {
    mode: 'production',
    PROPOSAL_SERVICE_URL: 'https://services.leadconnectorhq.com/proposals',
    INVOICE_SERVICE_URL: 'https://services.leadconnectorhq.com/invoices',
  },
}

const override = false

const overrideConfig = {}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const envConfig = config[process.env.NODE_ENV]

export default override ? { ...envConfig, ...overrideConfig } : envConfig
