<script setup lang="ts">
import { useInitials, useSignature, useTypography } from '@/composable'
import { useDocumentStore } from '@/store/document'
import { CheckIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  UIModal,
  UIModalContent,
  UIModalFooter,
  UIModalHeader,
  UISelect,
  UITab,
  UITabs,
  UITextXsRegular,
  UITextSmNormal,
} from '@gohighlevel/ghl-ui'
import { computed } from 'vue'
import { VueSignaturePad } from 'vue-signature-pad'
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

const { t } = useI18n()

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  show: {
    type: Boolean,
  },
  signerName: {
    type: String,
    required: true,
  },
  alignToCenter: {
    type: Boolean,
    default: false,
  },
  forInitials: {
    type: Boolean,
    default: false,
  },
  height: {
    type: Number,
    default: 300,
  },
  width: {
    type: Number,
    default: 500,
  },
})

const emit = defineEmits(['update:show', 'update:signatureText'])
const store = useDocumentStore()
const { toCapitalize } = useTypography()
const businessName = computed(() => store.document.businessName)
const {
  signatureText,
  getImg,
  tabs,
  selectedColor,
  selectedFontFamily,
  activeTab,
  fonts,
  colors,
  signaturePad,
  errorMessage,
  resetError,
} = props.forInitials ? useInitials() : useSignature()

onMounted(() => {
  if (props.signerName) {
    signatureText.value = props.signerName
  }
})

const onChange = (e: Event) => {
  resetError()
  const value = (e.target as HTMLInputElement).value
  signatureText.value = value
}

const submitCallback = async () => {
  const dataImg = await getImg({
    width: props.width,
    height: props.height,
    text: signatureText.value || props.signerName,
  })
  if (errorMessage.value) return
  emit('update:signatureText', dataImg)
  emit('update:show', false)
}

function cancelCallback() {
  emit('update:show', false)
}

const options = computed(() => {
  return fonts.map(font => {
    return {
      label: font.replace(/'/g, ''),
      value: font,
    }
  })
})

const inputStyle = computed(() => {
  return {
    fontFamily: selectedFontFamily.value,
    color: selectedColor.value,
    fontSize: '5em',
    'object-fit': 'contain',
    'object-position': 'center',
    display: 'block',
    margin: 'auto',
  }
})
</script>

<template>
  <UIModal
    :show="show"
    @update:show="val => $emit('update:show', val)"
    :id="'signature_modal' + props.id"
    :key="'signature_modal' + props.id"
    :width="800"
    className="page:!w-screen page:h-screen page:!fixed page:!top-0 page:!left-0 page:!z-[500] page:p-4 page:rounded-none"
  >
    <template #header>
      <UIModalHeader
        id="modal-header"
        type="success"
        :title="
          props.forInitials
            ? t('documentViewer.initials')
            : t('documentViewer.signature')
        "
        :description="`${t('documentViewer.addADigital')} ${
          props.forInitials ? 'initial' : 'signature'
        } ${t('documentViewer.orAHandDrawn')}`"
        @close="cancelCallback"
      >
      </UIModalHeader>
    </template>
    <!--  content goes here  -->
    <UIModalContent>
      <UITabs ref="tabsInstRef" v-model:value="activeTab" type="line">
        <UITab v-for="tab in tabs" :key="tab" :name="tab">
          {{ t(`documentViewer.${tab.toLowerCase()}`) }}
        </UITab>
      </UITabs>
      <div class="py-4">
        <div class="border rounded-lg" v-if="activeTab === 'Draw'">
          <VueSignaturePad
            height="300px"
            ref="signaturePad"
            :options="{ onBegin: resetError }"
          ></VueSignaturePad>
        </div>
        <div v-else class="border rounded-lg">
          <div class="signature-container p-3 flex flex-col justify-between">
            <div class="flex justify-between">
              <UISelect
                class="w-64"
                id="select-id"
                v-model:value="selectedFontFamily"
                :options="options"
                placeholder="Select one"
              />
              <div class="flex-1">
                <div class="flex items-center justify-end space-x-3">
                  <div v-for="(color, index) in colors" :key="index">
                    <div
                      @click="selectedColor = color"
                      :class="[
                        selectedColor === color
                          ? 'border-2 border-yellow-400 w-8 h-8'
                          : 'w-6 h-6',
                      ]"
                      :style="{ backgroundColor: color }"
                      class="rounded-full flex justify-center items-center"
                    >
                      <CheckIcon
                        v-if="selectedColor === color"
                        class="w-4 h-4 text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="errorMessage">
              <UITextSmNormal class="text-error-500 m-0">{{
                errorMessage
              }}</UITextSmNormal>
            </div>
            <div class="w-full overflow-auto">
              <input
                :style="inputStyle"
                type="text"
                class="signature-input"
                :value="signatureText"
                @change="onChange"
              />
              <canvas
                id="canvas"
                class="hidden"
                :width="props.width"
                :height="props.height"
              ></canvas>
            </div>
          </div>
        </div>
        <div class="p-4">
          <UITextXsRegular>
            {{
              t('documentViewer.signatureDisclaimer', {
                businessName: toCapitalize(businessName),
              })
            }}
          </UITextXsRegular>
        </div>
      </div>
    </UIModalContent>

    <template #footer>
      <UIModalFooter
        :positiveText="t('documentViewer.acceptAndSign')"
        :negativeText="t('documentViewer.cancel')"
        @positive-click="submitCallback"
        @negative-click="cancelCallback"
        id="modal-footer"
        class="page:fixed page:w-screen page:p-4 page:bottom-0 page:left-0 page:box-border"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Aguafina+Script&family=Dancing+Script&family=Style+Script&family=Vujahday+Script&display=swap');

.signature-container {
  height: 275px;
}
.signature-input {
  width: 100%;
  font-size: 40px;
  height: auto;
  text-align: center;
  appearance: none;
  border-radius: 0px;
  border-bottom: 1px solid #000;
  outline: none;
}
</style>
