import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import Document from '@/pages/Document.vue'
import DocumentPreviewer from '@/pages/DocumentPreViewer.vue'
import ErrorPage from '@/pages/ErrorPage.vue'
import PaymentSuccess from '@/pages/PaymentSuccess.vue'
import PdfViewer from '@/pages/PdfViewer.vue'
import PublicDocumentForm from '@/pages/PublicDocumentForm.vue'

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/documents/v1/status/:referenceId',
    component: PaymentSuccess,
    name: 'status-success',
    props: () => ({ isDocumentViewer: true }),
    end: true,
    strict: true,
  },
  {
    path: '/documents/:documentId/:recipientId',
    component: Document,
    name: 'document',
    props: () => ({ isDocumentViewer: true }),
  },
  {
    path: '/documents/v1/:referenceId',
    component: Document,
    name: 'document-v1',
    props: () => ({ isDocumentViewer: true }),
  },
  {
    path: '/documents/preview/:documentId',
    component: DocumentPreviewer,
    name: 'document-preview',
    props: () => ({ isPreview: true }),
  },
  {
    path: '/documents/error/:type',
    component: ErrorPage,
    name: 'document-error',
    props: true,
  },
  {
    path: '/documents/pdf-viewer/:documentId',
    component: PdfViewer,
    name: 'pdf-viewer',
    props: () => ({ isPreview: false, isPDFView: true }),
  },
  {
    path: '/documents/doc-form/:documentId',
    component: PublicDocumentForm,
    name: 'public-document-form',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export { router }
