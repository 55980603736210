import { DocumentServices } from '@/service/DocumentService'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const loading = ref(false)
export const usePublicForm = () => {
  const route = useRoute()
  const id = route.params.documentId as string

  const submitForm = async (payload: {
    firstName: string
    lastName: string
    email: string
  }) => {
    loading.value = true
    try {
      const response = await DocumentServices.savePublicFormResponse({
        ...payload,
        id,
        locale: (route.query.locale as string) || 'en-US',
      })
      if (response && response.data.url) {
        window.location.href = response.data.url
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const confirmFormValidity = async () => {
    try {
      loading.value = true
      const resp = await DocumentServices.validateForm(id)
      return resp.data
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  return {
    submitForm,
    loading,
    confirmFormValidity,
  }
}
