<script setup lang="ts">
import { usePublicForm } from '@/composable/usePublicForm'
import { useLoaderMessages } from '@gohighlevel/ghl-proposals-common'
import {
  UICard,
  UIInput,
  UIForm,
  UIFormItem,
  UIButton,
  UISpin,
  UITextXsRegular,
  UITextLgMedium,
  UITextMdNormal,
} from '@gohighlevel/ghl-ui'
import { onMounted, ref } from 'vue'

const model = ref({
  firstName: '',
  lastName: '',
  email: '',
})
const formRef = ref()
const loadingMessage = ref('')
const error = ref(false)

const messagesWithTime = [
  { message: 'Saving...', time: 5000 },
  { message: 'Please wait...', time: 5000 },
  { message: 'Preparing the document for you...', time: 30000 },
  { message: 'Almost done...', time: 30000 },
]

const { submitForm, loading, confirmFormValidity } = usePublicForm()
const { loadingText, overrideMessage, start, stop } =
  useLoaderMessages(messagesWithTime)

onMounted(async () => {
  resetForm()
  loadingMessage.value = 'Loading...'
  confirmFormValidity()
    .then(data => {
      loadingMessage.value = ''
      if (data.valid === false) {
        error.value = true
      }
    })
    .catch(() => {
      error.value = true
    })
})

const rules = {
  firstName: {
    required: true,
    message: 'Please input first name',
    trigger: 'blur',
  },
  lastName: {
    required: true,
    message: 'Please input last name',
    trigger: 'blur',
  },
  email: {
    required: true,
    message: 'Please input email',
    trigger: ['input'],
    validator: (_rule: any, value: any) => {
      if (!value) {
        return new Error('Please input email')
      }
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        return new Error('Please input a valid email')
      }
      return true
    },
  },
}

const resetForm = () => {
  model.value.firstName = ''
  model.value.lastName = ''
  model.value.email = ''
}

const handleValidateClick = () => {
  formRef.value
    .getForm()
    .validate()
    .then(() => {
      const form = formRef.value.getForm()
      const formValues = form.model
      start()
      submitForm(formValues)
        .then(() => {
          overrideMessage('Redirecting to the document...')
          resetForm()
        })
        .catch(() => {
          error.value = true
        })
        .finally(() => {
          stop()
        })
    })
    .catch(e => {
      throw new Error(e)
    })
}
const text = `
  Lorem ipsum odor amet, consectetuer adipiscing elit. Nulla luctus
  ultricies; eu felis amet dictum potenti taciti. Montes quisque
  posuere parturient conubia vehicula dignissim. Lacinia eleifend
  convallis ultricies, ad platea massa mollis taciti. Ad placerat
  senectus dictum convallis dis aliquam hac ridiculus. Nulla
  imperdiet faucibus nullam accumsan sagittis mus natoque elementum.
  Eros scelerisque tempus dis taciti ultricies feugiat hac; ex amet.
  Vitae placerat maecenas integer faucibus, suspendisse sagittis
  dolor.
`
const dummyTextArray = new Array(7).fill(text)
</script>

<template>
  <div
    class="w-screen h-screen overflow-hidden bg-gray-50 flex"
    :class="{ flex: error }"
  >
    <div
      v-if="error"
      class="flex items-center justify-center flex-col flex-1 text-center"
    >
      <UITextLgMedium>{{ $t('templates.docForm.invalidLink') }}</UITextLgMedium>
      <UITextMdNormal>{{
        $t('templates.docForm.invalidLinkDescription')
      }}</UITextMdNormal>
    </div>
    <div
      v-else
      class="w-full h-full relative flex-1 flex items-center justify-center"
    >
      <UICard class="max-w-2xl w-auto mx-auto rounded-xlg z-[4]">
        <template #header>
          <div class="flex items-center">
            <div>
              <UITextLgMedium class="text-gray-900">
                {{ $t('templates.docForm.title') }}
              </UITextLgMedium>
              <UITextSmNormal class="text-gray-500">
                {{ $t('templates.docForm.description') }}
              </UITextSmNormal>
            </div>
          </div>
        </template>
        <div class="relative">
          <UIForm
            id="form"
            :model="model"
            :rules="rules"
            :inline="false"
            ref="formRef"
            class="mx-auto m-0"
          >
            <UIFormItem :label="$t('common.firstName')" path="firstName">
              <UIInput
                v-model="model.firstName"
                :placeholder="$t('common.firstName')"
                id="public-form-first-name"
              />
            </UIFormItem>
            <UIFormItem :label="$t('common.lastName')" path="lastName">
              <UIInput
                v-model="model.lastName"
                :placeholder="$t('common.lastName')"
                id="public-form-last-name"
              />
            </UIFormItem>
            <UIFormItem :label="$t('common.email')" path="email">
              <UIInput
                v-model="model.email"
                :placeholder="$t('common.email')"
                id="public-form-email"
              />
            </UIFormItem>
            <UIFormItem>
              <UIButton
                :loading="loading"
                :disabled="loading"
                class="mx-auto w-full"
                id="public-form-submit-button"
                type="primary"
                @click="handleValidateClick"
                >Submit</UIButton
              >
            </UIFormItem>
          </UIForm>
        </div>
      </UICard>
      <div
        class="pseudo-document absolute top-0 left-0 w-full h-full z-1 bg-[#f2f7fa]"
      >
        <div
          class="backdrop absolute top-0 left-0 w-full h-full"
          style="
            background-color: rgb(128 128 128);
            backdrop-filter: blur(100px);
          "
        ></div>
        <div
          style="height: 1056px; max-width: 816px; background-color: #fff"
          class="shadow-page mt-20 mx-auto"
        >
          <div style="padding: 48px">
            <p
              style="filter: blur(4px)"
              v-for="(text, i) of dummyTextArray"
              v-bind:key="i"
            >
              {{ text }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="absolute left-0 top-0 h-full w-full flex items-center justify-center z-10 bg-[rgba(255,255,255,0.8)] flex-col"
      >
        <UISpin />
        <UITextXsRegular class="mt-2 font-medium">{{
          loadingText
        }}</UITextXsRegular>
      </div>
    </div>
  </div>
</template>
