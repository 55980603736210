import axios from 'axios'
import { computed } from 'vue'
import config from '../config'
import { useRoute } from 'vue-router'

const isNotProduction = () => process.env.NODE_ENV !== 'production'

const route = computed(() => useRoute())
const getHeader = () => {
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
    ...(isNotProduction() &&
    route.value &&
    route.value.query &&
    route.value.query['developer_version']
      ? { developer_version: route.value.query['developer_version'] }
      : {}),
  }
}

export const requests = (host?: string) => {
  const baseUrl = host || config.restApiUrl
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  })

  return axiosInstance
}
