import { ref } from 'vue'

export function useLoaderMessages(
  messagesWithTime: { message: string; time: number }[]
) {
  const loadingText = ref<string>('')
  let timerId: number | null = null
  let messageIndex = 0

  const showMessages = () => {
    if (messageIndex < messagesWithTime.length) {
      const { message, time } = messagesWithTime[messageIndex]
      loadingText.value = message

      timerId = window.setTimeout(() => {
        messageIndex++
        showMessages()
      }, time)
    }
  }

  const overrideMessage = (newMessage: string) => {
    if (timerId !== null) {
      clearTimeout(timerId)
    }
    loadingText.value = newMessage
    messageIndex++
    showMessages()
  }

  const start = () => {
    loadingText.value = ''
    messageIndex = 0
    showMessages()
  }

  const stop = () => {
    if (timerId !== null) {
      clearTimeout(timerId)
      timerId = null
    }
    loadingText.value = ''
    messageIndex = messagesWithTime.length
  }
  return {
    loadingText,
    overrideMessage,
    start,
    stop,
  }
}
